import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  PageNav,
  AltPanels,
} from 'layouts';
import {
  Banner,
  Breadcrumbs,
} from 'components';
import { PAGES, BASE_URL } from 'config';

const BREADCRUMBS = [
  {
    name: PAGES.ABOUT.TITLE,
    absolute_slug: PAGES.ABOUT_STORY.PATH,
  },
  {
    name: PAGES.ABOUT_STORY.TITLE,
    absolute_slug: PAGES.ABOUT_STORY.PATH,
  },
];

const AboutStoryPage = () => (
  <Layout page="about/story">
    <Helmet
      title="How Haka Tours Became the Award-Winning NZ Tour Operator | Haka Tours"
      meta={[
        {
          name: 'description', content: `Haka Tours was founded in 2007 when one man decided to reimagine how small group adventure tours were run. Since then,
          there's been no looking back, and now we're regarded as New Zealand’s leading small group adventure operator.`,
        },
        { name: 'og:title', content: 'How Haka Tours Became the Award-Winning NZ Tour Operator | Haka Tours' },
        {
          name: 'og:description', content: `Haka Tours was founded in 2007 when one man decided to reimagine how small group adventure tours were run. Since then,
          there's been no looking back, and now we're regarded as New Zealand’s leading small group adventure operator.`,
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/our-story/` }]}
    />

    <Banner backgroundImage="https://hakatours-staging.imgix.net/000/our-story-banner.jpg?fit=crop&auto=format">
      <h2 className="c-heading c-heading--h0">Our Story</h2>
    </Banner>
    <PageNav pages={[PAGES.ABOUT_TRIPS, PAGES.ABOUT_STORY, PAGES.ABOUT_PURPOSE, PAGES.ABOUT_PEOPLE, PAGES.ABOUT_AWARDS, PAGES.ABOUT_FAQS, PAGES.ABOUT_REVIEWS]} />
    <section className="l-section">
      <div className="l-container">
        <Breadcrumbs list={BREADCRUMBS} />
      </div>
      <AltPanels items={[
        {
          title: 'FROM HUMBLE BEGINNINGS',
          content: `<p>Haka Tours was born almost two decades ago from the dreams of one man who wanted to reimagine how small group adventure tours were run. 
          From the very first tour in June 2007 until now there’s been no looking back, and we’re incredibly proud to be regarded as New Zealand’s leading small group adventure operator.
          </p>
          <p>As we’ve grown, we’ve stayed true to our core values which remain the key to our success. 
          We’re excited to have recently partnered with <a href="https://www.intrepidtravel.com/nz" target="_blank">Intrepid Travel</a> , who embody our same desire to tread lightly on our planet and to create impactful travel experiences for our guests.</p>
          `,
          image: {
            width: 652,
            height: 418,
            title: 'Example image title',
            url: 'https://static-hakatours.imgix.net/our-story-01b.jpg?w=652&h=418&fit=crop&auto=format',
            align: '',
          },
        },
        {
          title: 'ADVENTURE, PLUS & WINTER',
          content: `<p>With a natural playground on our doorstep, and to satisfy our customers’ thirst for adventure as much as our own, we added Snow Tours in 2008 and more recently a series of <a href="/new-zealand-tours" target="_blank">Winter Tours</a> to become a year-round adventure tour operator. 
          In 2019, we created a new category, <a href="/haka-plus-tours" target="_blank">Haka Plus Tours</a> aimed at those guests seeking more comfort and have proved highly popular. 
          With countless miles under our belts and knowing New Zealand like the back of our hands, we’re true experts at arranging <a href="/private-tours" target="_blank">Private Tours</a> for families and groups looking for a fully bespoke experience, whether it be self-drive or accompanied by a driver-guide. 
          </p>`,
          image: {
            width: 652,
            height: 418,
            title: 'Example image title',
            url: 'https://hakatours-staging.imgix.net/000/our-story-humble.jpg?w=652&h=418&fit=crop&auto=format',
            align: '',
          },
        },
        {
          title: 'DEVELOPING TRUE NEW ZEALAND EXPERTS',
          content: `<p>Becoming an expert tour guide is not something that happens overnight, and a huge upside to operating tours year-round is that many of our Tour Managers are able to make guiding a full-time job. 
          Many have stayed with us for years, honing their knowledge and experience. 
          Haka Tours is a family, and we’re passionate about delivering the very best tours – it’s what we live for!
          </p>`,
          image: {
            width: 652,
            height: 418,
            title: 'Example image title',
            url: 'https://hakatours-staging.imgix.net/000/our-story-developing-true-new.jpeg?w=652&h=418&fit=crop&auto=format',
            align: '',
          },
        },
        {
          title: 'OUR OWN NETWORK OF HAKA LODGES',
          content: `<p>After a full day exploring, we know where you lay your head for the night is super important, which is why we opened our very own network of upmarket hostels beginning with Christchurch in 2011.</p>
          <p>Now operating in 13 locations across New Zealand (Auckland, Aroaki Mt Cook the Bay of Islands, Christchurch, Lake Tekapo, Mount Cook, Taupo, Queenstown, Rotorua and Wellington), 
          having our own accommodation ensures we’re able to provide a high standard of accommodation to all our Haka Tours guests. 
          We believe our dorm rooms have the world's best bunk beds and for those who request an up-grade, all properties have private rooms. 
          We have also expanded and re-branded to become <a href="https://hakahouse.com/" target="_blank">Haka House</a> - a place to call home for all free-spirited travellers. 
          Think awesome locations, stunning scenery, cosy rooms, friendly vibes and modern facilities.
          </p>`,
          image: {
            width: 652,
            height: 418,
            title: 'Example image title',
            url: 'https://hakatours-staging.imgix.net/000/our-story-our-own-network-of.jpg?w=652&h=418&fit=crop&auto=format',
            align: '',
          },
        },
        {
          title: 'THEN CAME HAKA EDUCATIONAL TOURS',
          content: `<p>Educational tourism has always been part and parcel of our product range since our first international school travelled with us in 2009. 
          In 2015, we decided to really focus on this market and operate cultural, sporting and education-based tours for schools and universities within New Zealand and globally. 
          We are proud to say we have expanded into over 10 countries with our <a href="https://hakaeducationaltours.com/" target="_blank">Haka Educational Tours</a> brand and then launched our dedicated <a href="https://hakasportstours.com/" target="_blank">Haka Sports Tours</a> brand.
          </p>`,
          image: {
            width: 652,
            height: 418,
            title: 'Example image title',
            url: 'https://hakatours-staging.imgix.net/000/our-story-then-came-haka-educational.jpg?w=652&h=418&fit=crop&auto=format',
            align: '',
          },
        },
        {
          title: 'A NEW ZEALAND SUCCESS STORY!',
          content: `<p>More than a decade on, we couldn’t be prouder of how far we’ve come. 
          As a true New Zealand grass-roots start-up built on just a few thousand dollars seed capital, our aspiration to be a leading tourism operator has definitely become a dream reality. 
          We are stoked to have won a number of awards through the years with most recent accolades being the 2019 Adventure Tourism Awards’ inaugural ‘Cultural Tourism’ award for New Zealand; 
          names as one of the 2019 Deloitte Fast 50 and to be the Supreme & Category winner at the 2019 ExportNZ Awards. 
          Discover more about <a href="/about-us/our-awards/" target="_blank">our Awards</a>.
          </p>`,
          image: {
            width: 652,
            height: 418,
            title: 'Example image title',
            url: 'https://hakatours-staging.imgix.net/000/our-story-success.jpg?w=652&h=418&fit=crop&auto=format',
            align: '',
          },
        },
      ]}
      />
    </section>

    <section className="l-section">
      <div className="l-container l-container--small u-text--center">
        <p>&nbsp;</p>
      </div>
    </section>

    {/* <BlogList */}
    {/* title="The Latest Haka News" */}
    {/* list={[1, 2]} */}
    {/* /> */}

  </Layout>
);

export default AboutStoryPage;
